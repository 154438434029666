import React from 'react';
import ReactDOM from 'react-dom/client';

class Chat extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            myText : "Chat text"
        }
    }

   componentDidUpdate(prevProps){
    console.log('componentDidUpdate')
   } 
   render() {
    return (
        <p> Chaild {this.state.myText} </p>
    )
   }
}

export default Chat