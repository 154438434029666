import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneInputComponent = ({ onChange }) => {
    const [phoneNumber, setPhoneNumber] = useState('');

    const handlePhoneChange = (value) => {
        // Устанавливаем максимальную длину в 15 символов
        if (value && value.length > 15) {
            return;
        }
        onChange(value);
    };

    return (
        <div className="col col-lg-9 ">
            <PhoneInput
                country={'ru'}
                // defaultCountry={"RU"}
                // countries={["RU", "US"]}
                // countryReadOnly={true}
                inputClass="my-phone-input"
                countryCallingCodeEditable={false}
                countryCodeEditable={false}
                // addInternationalOption={false}
                placeholder="+7 (986) 543-21-00"
                value={phoneNumber}
                name={"phonenumber"}
                onChange={handlePhoneChange}
                inputProps={{
                    maxLength: 18, // Максимальная длина вводимого текста
                }}
            />
            {/*<div>Formatted Phone Number: {phoneNumber}</div>*/}
        </div>
    );
};

export default PhoneInputComponent;
