import React from 'react';
import Chat from './components/Chat';
import MyForm from './components/MyForm';
import DraggableComponent from './components/Drag';

function Welcome() {
    return <h1>Welcom to Mattermost Chat</h1>;
}

class App extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            helpText : "Help text"
        }
    }
    render() {
        return (
      <MyForm />
        )
    }
}

export default App;
