import React, { useState } from 'react';
import axios from 'axios';
import PhoneInputComponent from "./PhoneInputComponent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const MyForm = () => {

    var timestamp = 0;

    const initialFormData = {
        lastname: '',
        firstname: '',
        email: '',
        message: '',
        phonenumber:'',
        messtype:'',
    };

    const [formData, setFormData] = useState(initialFormData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
        [name]: value,
        });
        // console.log("Change name", name, " Value:", value)
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const currentDate = new Date();
        const curentimestamp = currentDate.getTime();
        if (curentimestamp - timestamp < 60000) {
            notify("Ваше сообщение уже было отправлено", "warning");
            return
        }
        try {
                //console.log("Send formData", formData)
                axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
                axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';

                const response = await axios.post('/client.php',   formData);

                if (response.status === 200) {
                    notify("Ваше обращение успешно отправлено", "success");
                    const currentDate = new Date();
                    timestamp = currentDate.getTime();
                    // setFormData(initialFormData);
                } else {
                    notify("Ошибка отправки, проверьте данные", "warning");
                    console.error('Неожиданный код ответа:', response.status);
                }
            } catch (error) {
                if (error.response.status === 400) {
                    notify("Ошибка, проверьте данные", "warning")
                } else {
                    notify("Ошибка сервера", "error")
                }
            }

    };
    const [phonenumber, setChildValue] = useState('');

    const handlePhoneChange = (value) => {
        setChildValue(value);
        setFormData({
            ...formData,
            ['phonenumber']: value,
        });
    };

    const notify = (text, type) => {
        switch(type) {
            case 'success':
                toast.success(text);
                break;
            case 'error':
                toast.error(text);
                break;
            case 'warning':
                toast.warning(text);
                break;
            default:
                toast.success('Default');
        }

    };

    // Select и модальное окно
    const [selectedOption, setSelectedOption] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleSelectChange = (e) => {

        const selectedValue = e.target.value;

        setSelectedOption(selectedValue);
        setFormData({
            ...formData,
            ['messtype']: selectedValue,
        });
        // Открываем модальное окно при выборе определенного значения
        console.log('On select');
        if (selectedValue === 'agents') {
            console.log('Open Modal');
            setIsModalOpen(true);
        }

    };
    const closeModal = () => {
        setIsModalOpen(false);
        console.log("Close Modal");
        setSelectedOption("");
    };
    // Конец модального окна

    return (
        <div className="">
            <ToastContainer />

            <div className="col-lg-8 col-xl-8">
                <form onSubmit={handleSubmit}>

                    <div className="myselect">
                        <label className="form-control">
                            <select className="form-select" name="messtype" value={selectedOption}
                                    onChange={handleSelectChange}>
                                <option value="consulting">Тип обращения...</option>
                                <option value="credit">Заявка на кредит</option>
                                <option value="agents">Форма агента</option>
                                <option value="employment">Трудоустройство</option>
                                <option value="consulting">Консультация</option>
                            </select>
                        </label>

                        {isModalOpen && (
                            <div className="modal-overlay">
                                <div className="modal2">
                                    <button className="close-button" onClick={closeModal}>&times;</button>
                                    <iframe src="https://b24-v1w3r4.bitrix24.site/crm_form_vfcrz/" width="100%" height="800px" frameBorder="0"></iframe>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="myselect" >
                        <input className="form-control" id="lastname" type="lastname" name="lastname"
                               placeholder="Enter your name..." data-sb-validations="required" onChange={handleChange}/>
                        <label htmlFor="lastname">Фамилия</label>
                        <div className="invalid-feedback" data-sb-feedback="name:required">A name is required.</div>
                    </div>
                    <div className="form-floating mb-1">
                        <input className="form-control" id="firstname" type="firstname" name="firstname"
                               placeholder="Enter your name..." data-sb-validations="required" onChange={handleChange}/>
                        <label htmlFor="firstname">Имя</label>
                        <div className="invalid-feedback" data-sb-feedback="name:required">A name is required.</div>
                    </div>

                    <div className="form-floating mb-3">

                            {/*<input className="form-control" id="phone" type="tel" placeholder="(912) 345-6789" data-sb-validations="required" />*/}
                            <PhoneInputComponent className="col-lg-auto" id="phone" onChange={handlePhoneChange}/>
                            <div className="invalid-feedback" data-sb-feedback="phone:required">A phone number is
                                required.
                            </div>

                    </div>


                    <div className="myselect">
                        <input className="form-control" id="email" type="email" name="email"
                               placeholder="name@example.com" data-sb-validations="required,email"
                               onChange={handleChange}/>
                        <label htmlFor="email">Email</label>
                        <div className="invalid-feedback" data-sb-feedback="email:required">An email is required.</div>
                        <div className="invalid-feedback" data-sb-feedback="email:email">Email is not valid.</div>
                    </div>


                    <div className="form-floating mb-3" style={{visibility: 'hidden'}}>
                        <textarea className="form-control" id="message" type="message" name="message"
                                  onChange={handleChange} placeholder="Enter your message here..."
                                  style={{height: '0rem'}} data-sb-validations="required"></textarea>
                        <label htmlFor="message"></label>
                        <div className="invalid-feedback" data-sb-feedback="message:required">A message is required.
                        </div>
                    </div>

                    <div className="d-none" id="submitErrorMessage">
                        <div className="text-center text-danger mb-3">Error sending message!</div>
                    </div>
                        <button className="btn btn-primary" id="submitButton" type="submit">ПОЛУЧИТЬ ДЕНЬГИ</button>
                </form>
            </div>
        </div>
    );
};

export default MyForm;
